import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Logo from "./images/logo.png"
import Blogo from "./images/blogo.png"
import './index.css';
import { Icon } from '@iconify/react';
import Wrapper from './Navbar/Navbar.styled';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const contractABI = require('./contractABI.json'); // Make sure to add your contract ABI here
const contractAddress = '0xe042DC9d91B5ce6E63579ae1287E993cDf41F8cE'; // Replace with your contract's address
const tokenABI = require('./tokenABI.json'); // Token contract ABI
const tokenAddress = '0xE8a4F717Ac5b08BcCc7240D649af653b2577b36a'; // Replace with your token contract address

const MySwal = withReactContent(Swal);

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [contractBalance, setContractBalance] = useState('');
  const [userReward, setUserReward] = useState('0.00');
  const [userStakedAmount, setUserStakedAmount] = useState('0.00');
  const [totalStaked, setTotalStaked] = useState('');
  const [tokenPrice, setTokenPrice] = useState('');
  const [userTokenBalance, setUserTokenBalance] = useState('0.00');
  const [userTotalRewardsClaimed, setUserTotalRewardsClaimed] = useState('0.00');
  const [isLoading, setIsLoading] = useState(false);
  const web3ReadOnly = new Web3(new Web3.providers.HttpProvider("https://connect.bit-rock.io"));
  const contractReadOnly = new web3ReadOnly.eth.Contract(contractABI, contractAddress);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }; 

  useEffect(() => {
    // Function to fetch user staked amount
    const fetchStakedAmount = async () => {
      if (account && contract) {
        try {
          const stakedWei = await contract.methods.stakingBalance(account).call();
          // Convert the staked amount from Wei to tokens (with 9 decimals)
          const stakedTokens = (stakedWei / Math.pow(10, 9)).toFixed(3);
          setUserStakedAmount(stakedTokens); // Update the user's staked amount in state
        } catch (error) {
          console.error('Error fetching staked amount:', error);
        }
      }
    };
  
    // Call the function initially to fetch the staked amount
    fetchStakedAmount();
  
    // Set up interval for real-time updates (e.g., every 10 seconds)
    const intervalId = setInterval(fetchStakedAmount, 2000); // Fetch every 2 seconds
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [account, contract]); // Dependencies: account and contract  

  useEffect(() => {
    // Function to fetch total staked tokens
    const fetchTotalStaked = async () => {
      try {
        const totalStakedWei = await contractReadOnly.methods.getTotalStaked().call();
        const totalStakedTokens = totalStakedWei / Math.pow(10, 9); // Adjust for 9 decimals
        setTotalStaked(totalStakedTokens.toFixed(3)); // Update the total staked state
      } catch (error) {
        console.error('Error fetching total staked amount:', error);
      }
    };
  
    // Call the function initially to fetch the total staked amount
    fetchTotalStaked();
  
    // Set up interval for real-time updates (e.g., every 10 seconds)
    const intervalId = setInterval(fetchTotalStaked, 2000); // Fetch every 10 seconds
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchUserTotalRewardsClaimed = async () => {
      if (account && contractReadOnly) {
        try {
          const rewardsClaimed = await contractReadOnly.methods.getTotalRewardsClaimed(account).call();
          // Assuming the result is in Wei and you want to display it in BNB
          const rewardsClaimedBNB = web3ReadOnly.utils.fromWei(rewardsClaimed, 'ether');
          // Format the rewards claimed to 2 decimal places
          const formattedRewardsClaimed = parseFloat(rewardsClaimedBNB).toFixed(6);
          setUserTotalRewardsClaimed(formattedRewardsClaimed);
        } catch (error) {
          console.error('Error fetching user total rewards claimed:', error);
        }
      }
    };
  
    fetchUserTotalRewardsClaimed();
  }, [account, contractReadOnly]); // Add account and contractReadOnly to the dependency array

  useEffect(() => {
    // Function to fetch token price
    const fetchTokenPrice = async () => {
      try {
        const tokenPriceWei = await contractReadOnly.methods.getTokenPrice().call();
        // Since the price is returned in wei, convert it to ether for display
        const tokenPrice = web3ReadOnly.utils.fromWei(tokenPriceWei, 'ether');
        const formattedTokenPrice = parseFloat(tokenPrice).toFixed(10);
        setTokenPrice(formattedTokenPrice); // Update the token price state
      } catch (error) {
        console.error('Error fetching token price:', error);
      }
    };
  
    // Call the function initially to fetch the token price
    fetchTokenPrice();
  
    // Set up interval for real-time updates (e.g., every 10 seconds)
    const intervalId = setInterval(fetchTokenPrice, 2000); // Fetch every 10 seconds
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  // useEffect(() => {
  //   const fetchTokenBalance = async () => {
  //     if (account && tokenContract) {
  //       try {
  //         const balanceWei = await tokenContract.methods.balanceOf(account).call();
  //         const balanceToken = balanceWei / Math.pow(10, 9); // Adjust for 9 decimals
  //         setUserTokenBalance(balanceToken.toFixed(9)); // Format to 9 decimal places
  //       } catch (error) {
  //         console.error('Error fetching token balance:', error);
  //       }
  //     }
  //   };
  
  //   fetchTokenBalance();
  // }, [account, tokenContract]);

  useEffect(() => {
    const fetchTokenBalance = async () => {
      if (account && tokenContract) {
        try {
          const balanceWei = await tokenContract.methods.balanceOf(account).call();
          const balanceToken = balanceWei / Math.pow(10, 9); // Adjust for 9 decimals
          setUserTokenBalance(balanceToken.toFixed(9)); // Format to 9 decimal places
        } catch (error) {
          console.error('Error fetching token balance:', error);
        }
      }
    };
  
    // Initial fetch when the component mounts
    fetchTokenBalance();
  
    // Set up interval to fetch balance every 10 seconds (10000 milliseconds)
    const intervalId = setInterval(fetchTokenBalance, 1000); // Fetch every 10 seconds
  
    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [account, tokenContract]); // Dependencies: account and tokenContract  

  useEffect(() => {
    // Function to fetch user rewards
    const fetchRewards = async () => {
      if (account && contract) {
        try {
          const rewardWei = await contract.methods.calculateEstimatedReward(account).call();
          const rewardBNB = web3.utils.fromWei(rewardWei, 'ether');
          const formattedUserReward = parseFloat(rewardBNB).toFixed(12);
          setUserReward(formattedUserReward); // Update the user's reward in state
        } catch (error) {
          console.error('Error fetching rewards:', error);
        }
      }
    };
  
    // Call the function initially to fetch the rewards
    fetchRewards();
  
    // Set up interval for real-time updates (e.g., every 10 seconds)
    const intervalId = setInterval(fetchRewards, 2000); // Fetch every 10 seconds
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [account, contract]); // Dependencies: account and contract

  useEffect(() => {
    if (web3 && contractAddress) {
      getContractBalance();
    }
  }, [web3, contractAddress]);

  useEffect(() => {
    // Check if MetaMask is already connected when the component mounts
    if (window.ethereum) {
      const isDisconnected = localStorage.getItem('walletDisconnected');
      if (isDisconnected !== 'true') {
        const web3Instance = new Web3(window.ethereum);
        web3Instance.eth.getAccounts().then(accounts => {
          if (accounts.length > 0) {
            connectWallet();
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (web3) {
      const tokenContractInstance = new web3.eth.Contract(tokenABI, tokenAddress);
      setTokenContract(tokenContractInstance);
    }
  }, [web3]);

  const getContractBalance = async () => {
    try {
      const balanceInWei = await web3.eth.getBalance(contractAddress);
      const balanceInBNB = web3.utils.fromWei(balanceInWei, 'ether');
      const formattedContractBalance = parseFloat(balanceInBNB).toFixed(4);
      setContractBalance(formattedContractBalance);
    } catch (error) {
      console.error('Error getting contract balance:', error);
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const networkId = await web3Instance.eth.net.getId();

        if (networkId !== 7171) { // 56 is the network ID for BSC Mainnet
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x38' }], // Hexadecimal value of 56
            });
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask
            if (switchError.code === 4902) {
              MySwal.fire({
                icon: 'warning',
                title: 'Please add the Binance Smart Chain network to MetaMask.',
              });
            }
            return;
          }
          MySwal.fire({
            icon: 'success',
            title: 'Connected to the Binance Smart Chain!',
          });
        } else {
          // MySwal.fire({
          //   icon: 'success',
          //   title: 'Wallet connected successfully!',
          // });
        }
        setWeb3(web3Instance);
        setAccount(accounts[0]);
        const contractInstance = new web3Instance.eth.Contract(contractABI, contractAddress);
        setContract(contractInstance);
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
        MySwal.fire({
          icon: 'success',
          title: 'Failed to connect wallet.',
        });
      }
    } else {
      MySwal.fire({
        icon: 'info',
        title: 'Please install MetaMask!',
      });
    }
  };

  const disconnectWallet = () => {
    setWeb3(null);
    setAccount(null);
    setContract(null);
    localStorage.setItem('walletDisconnected', 'true');
    MySwal.fire({
      icon: 'info',
      title: 'Wallet disconnected.',
    });
  };
  
  const stakeTokens = async () => {
    if (!stakeAmount) {
      MySwal.fire({
        icon: 'info',
        title: 'Enter an amount to stake.',
      });
      return;
    }

    // Check if there are rewards to claim before staking more tokens
    const userRewards = parseFloat(userReward); // Assuming 'userReward' is already in a suitable format
    if (userRewards > 0) { 
      MySwal.fire({
        icon: 'warning',
        title: 'You have unclaimed rewards!',
        text: 'Would you like to claim & stake your tokens?',
        confirmButtonText: 'Claim & Stake',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          claimAndStakeRewards();
        }
      });      
      return; // Prevent further execution until the user claims their rewards or cancels
    }
  
    try {
      setIsLoading(true);
      const decimals = new BigNumber(10).pow(9);
      const weiAmount = new BigNumber(stakeAmount).multipliedBy(decimals).toString();
  
      // Check the current allowance
      const allowance = await tokenContract.methods.allowance(account, contractAddress).call();
      if (new BigNumber(allowance).isLessThan(new BigNumber(weiAmount))) {
        // If allowance is insufficient, prompt user to approve staking contract to spend tokens
        await tokenContract.methods.approve(contractAddress, weiAmount).send({ from: account });
        MySwal.fire({
          icon: 'info',
          title: 'Approval successful. Please confirm staking transaction.',
        });
      }
  
      // After approval, stake the tokens
      const stakeTx = await contract.methods.stake(weiAmount).send({ from: account });
      if (stakeTx.status) {
        MySwal.fire({
          icon: 'success',
          title: 'Stake successful!',
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Stake transaction failed.',
        });
      }
    } catch (error) {
      console.error('Staking error:', error);
      MySwal.fire({
        icon: 'error',
        title: `Staking failed: ${error.message}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const claimAndStakeRewards = async () => {
    try {
      // Claim rewards first
      await contract.methods.claimRewards().send({ from: account });
  
      // Convert the staking amount from tokens to the smallest unit
      const stakingAmount = new BigNumber(stakeAmount);
      const stakingAmountInSmallestUnit = stakingAmount.shiftedBy(9); // Shift by 9 decimal places for token
  
      // Approve the staking contract to spend the tokens
      await tokenContract.methods.approve(contractAddress, stakingAmountInSmallestUnit.toFixed()).send({ from: account });
  
      // After the approval, stake the tokens
      await contract.methods.stake(stakingAmountInSmallestUnit.toFixed()).send({ from: account });
  
      MySwal.fire({
        icon: 'success',
        title: 'Rewards claimed and staked successfully!',
      });
  
      // Reset user reward and stake amount on UI
      setUserReward('0.00');
      setStakeAmount(''); // Clear the staking input field
  
      // Update other UI elements as needed, e.g., staked amount, token balance
  
    } catch (error) {
      console.error('Claim & Stake error:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Failed to claim & stake rewards.',
        text: error.message,
      });
    }
  };

  useEffect(() => {
    // Function to fetch contract balance using web3ReadOnly instance
    const fetchContractBalanceReadOnly = async () => {
      try {
        const balanceInWei = await web3ReadOnly.eth.getBalance(contractAddress);
        const balanceInBNB = web3ReadOnly.utils.fromWei(balanceInWei, 'ether');
        const formattedContractBalance = parseFloat(balanceInBNB).toFixed(4);
        setContractBalance(formattedContractBalance); // Update the contract balance state
      } catch (error) {
        console.error('Error fetching contract balance (read-only):', error);
      }
    };
  
    // Call the function initially to fetch balance when the component mounts
    fetchContractBalanceReadOnly();
  
    // Set up interval for real-time updates (e.g., every 10 seconds)
    const intervalId = setInterval(fetchContractBalanceReadOnly, 20000); // Fetch every 10 seconds
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  
  const unstakeTokens = async () => {
    if (!unstakeAmount) {
      MySwal.fire({
        icon: 'info',
        title: 'Enter an amount to unstake.',
      });
      return;
    }
  
    try {
      setIsLoading(true); // Start the loader
  
      const weiAmount = (unstakeAmount * Math.pow(10, 9)).toString(); // Adjust for 9 decimals and convert to string
      await contract.methods.unstake(weiAmount).send({ from: account });
      MySwal.fire({
        icon: 'success',
        title: 'Unstake successful!',
      });
    } catch (error) {
      console.error('Unstake error:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Unstake failed.',
      });
    } finally {
      setIsLoading(false); // Stop the loader
    }
  };  

  const claimRewards = async () => {
    try {
      setIsLoading(true);
      await contract.methods.claimRewards().send({ from: account });
      MySwal.fire({
        icon: 'success',
        title: 'Claim successful!',
      });
    } catch (error) {
      console.error('Claim error:', error);
      MySwal.fire({
        icon: 'warning',
        title: 'Claim failed.',
      });
    } finally{
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
   
<Wrapper>
<nav>
<div className="container">

      <p className='connectAccount'>Connected Account: {account}</p>

<div className="navbarSingleItem">
                <div className="nav_logo"data-aos="fade-down" data-aos-duration="1000">
                <a href="/"><img src={Logo} className='img-fluid logo_Header' loading='lazy' alt="logo" /></a>
                  </div>
                <div className="menu">
                    <ul>

                    <li data-aos="fade-down" data-aos-duration="1200">
                    <a smooth to="/#">
        </a>
                    </li>
                    <li data-aos="fade-down" data-aos-duration="1400">
                    <a href='http://www.stakeapetoken.org/'>
          Staking
        </a>
                    </li>
                    <li  data-aos="fade-down" data-aos-duration="1600">
                    <a href='https://apetoken.net/' target='blank'>
          Tokenomics
        </a>
        
                    </li>
         
        
           
                    </ul>
                    <div className="connectButton">
    {account ? (
          <>
 
            <button className="wallet-button" onClick={disconnectWallet}>
              Disconnect Wallet
            </button>
          </>
        ) : (
          <button className="wallet-button" onClick={connectWallet}>
            Connect MetaMask
          </button>
        )}
      
    </div>
                </div>
         
            </div>


</div>

 
</nav>

<div className="mobile_menu">
<div className="logo"data-aos="fade-down" data-aos-duration="1000">
<a href="/"><img src={Logo} className='img-fluid' loading='lazy' alt="logo" /></a>
                </div>
<div className={`offcanvas-menu ${isMenuOpen ? 'open' : ''}`}>
    <button onClick={toggleMenu} className="toggle-button">
        {isMenuOpen ? (
          <span className='icon'>
<Icon icon="carbon:close" />
          </span>
        ) : (
       <span className='icon'>
           <Icon icon="fe:bar" />
       </span>
        )}
      </button>

      <div className="menu-content">
        <ul>
        <li data-aos="fade-down" data-aos-duration="1200">
                    <a smooth to="/#">
          Dashboard
        </a>
                    </li>
                    <li data-aos="fade-down" data-aos-duration="1400">
                    <a smooth to="/#feature">
          Staking
        </a>
                    </li>
                    <li  data-aos="fade-down" data-aos-duration="1600">
                    <a smooth to="/#roadmap">
          Tokenomics
        </a>
        
                    </li>
         
        </ul>
        <div className="mobileButton">
        <div className="connectButton">
    {account ? (
          <>
            <p className='connectAccount'>Connected Account: {account}</p>
            <button className="wallet-button" onClick={disconnectWallet}>
              Disconnect Wallet
            </button>
          </>
        ) : (
          <button className="wallet-button" onClick={connectWallet}>
            Connect MetaMask
          </button>
        )}
      
    </div>
                </div>
      </div>
      {isMenuOpen && (
        <div className="close-icon" onClick={toggleMenu}>
          Close Icon ✕
        </div>
      )}
    </div>
</div>
</Wrapper>

<div className="staking">
  <div className="container">

    <div className="col-md-12">
    <div className="staking_title">
        <h4>Staking</h4>
      </div>
    </div>

    <div className="row">
      <div className="col-md-6">
        <div className="common_item">
         <div className="tab_header">
         <button
          className={activeTab === 1 ? 'active' : ''}
          onClick={() => handleTabClick(1)}
        >
       Staking
        </button>
        <button
          className={activeTab === 2 ? 'active' : ''}
          onClick={() => handleTabClick(2)}
        >
       Unstaking
        </button>
         </div>
        {activeTab === 1 && 

    <div className='botder_items' >
    <div className="ballanced">
      <div className="total_staked">
          <p>Total Staked</p>

          <h3><span> {totalStaked}</span> $APE</h3>
        </div>

        <div className="total_pool">
          <p>Total Pool Balance:</p>
          <h2><span>{contractBalance ? `${contractBalance}` : 'Loading...'}</span> BROCK</h2>
        </div>


  </div>
         {/* total staked end here */}

         <div className="content_pera">
          <p>A meme token based on the three wise monkeys. Bringing generational wealth to the Bitrock Blockchain.</p>
         </div>


         <div className="last_items">
          <div className="my_balance">
            <div className="balance_sheet">
              <h5>My Balance</h5>
            </div>
            <div className="balance_price">
            <h5>{userTokenBalance} $APE</h5>
            </div>

            
          </div>

          <div className="input-group">
        <input
          className="input-field apeField"
          type="text"
          value={stakeAmount}
          onChange={e => setStakeAmount(e.target.value)}
          placeholder="$APE"
        />
        <button
        onClick={() => setStakeAmount(Math.floor(userTokenBalance).toString())}
        style={{
          backgroundColor: '#000', // Gold color
          color: '#ffffff',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '4px',
          padding: '10px 20px',
          cursor: 'pointer',
          margin: '5px 0',
          borderRadius: '4px',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'
        }}
      >
        Max
      </button>
      <button className="action-button stakeButton" onClick={stakeTokens} disabled={isLoading}>
        {isLoading ? 'Staking...' : 'Stake'}
      </button>
      </div>
         </div>
</div>


}


{activeTab === 2 && 

<div className="border_items borderItems2">
<div className="ballanced">
  <div className="total_staked">
      <p>Total Staked</p>

      <h2><span> {totalStaked}</span> $APE</h2>
    </div>

    <div className="total_pool">
      <p>Total Pool Balance:</p>

      <h2><span>{contractBalance ? `${contractBalance}` : '0.00'}</span> BROCK</h2>
    </div>


  </div>
         {/* total staked end here */}

         <div className="content_pera">
          <p>A meme token based on the three wise monkeys. Bringing generational wealth to the Bitrock Blockchain.</p>
         </div>


         <div className="last_items">
          <div className="my_balance">
            <div className="balance_sheet">
              <h5>My Balance</h5>
            </div>
            <div className="balance_price">
            <h5>{userTokenBalance} $APE</h5>
            </div>

            
          </div>

          <div className="input-group">
          <input
          className="input-field apeField"
          type="text"
          value={unstakeAmount}
          onChange={e => setUnstakeAmount(e.target.value)}
          placeholder="$APE"
        />
        <button
        onClick={() => setUnstakeAmount(userStakedAmount)}  // Max button to set the staked amount
        style={{
          backgroundColor: '#000', // Gold color
          color: '#ffffff',
          fontWeight: 'bold',
          border: 'none',
          borderRadius: '4px',
          padding: '10px 20px',
          cursor: 'pointer',
          margin: '5px 0',
          borderRadius: '4px',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'
        }}
      >
        Max
      </button>
        <button className="action-button stakeButton" onClick={unstakeTokens} disabled={isLoading}>
        {isLoading ? 'Unstaking...' : 'Unstake'}
      </button>
      </div>
         </div>
        </div>
        }
        </div>
      </div>
      {/* secound item */}

      <div className="col-md-6">
        <div className="common_item right_common_item">
         <div className="tab_header rightHeader">
        <div className="token_price">
          <p>$APE TOKEN PRICE</p>
          <h4>{tokenPrice} BROCK</h4>
        </div>
        <div className="amount_claimed">
          <p>Amount Claimed $BROCK</p>
          <h4>{userTotalRewardsClaimed}</h4>
        </div>
         </div>

         {/* total staked */}
<div >
<div className="ballanced balanced2">
  <div className="total_staked">
      <p>You staked</p>

      <h2><span> {userStakedAmount}</span> $APE</h2>
    </div>

    <div className="total_pool">
      <p>Your APR</p>

      <h3><span>  {5} %</span> </h3>
    </div>


  </div>
         {/* total staked end here */}

   

         <div className="last_items last_items2">
          <div className="my_balance">
            <div className="balance_sheet rightAmount">
              <h5>Available to claim $BROCK:</h5>

              <h2> <span> <img src={Blogo} className='img-fluid' alt="logo" /></span>{userReward}</h2>
            </div>
            
            
          </div>

          <div className="input-group rightInput">
       
          <button className="action-button stakeButton" onClick={claimRewards} disabled={isLoading}>
            {isLoading ? 'Claiming...' : 'Claim'}
          </button>
      </div>



         </div>
</div>
        </div>
      </div>
      {/* second item end here */}
    </div>
  </div>
</div>
      <div className="copyRight">
        <div className="container">
            <div className="copyItem">
            <div className="copyrightText">
            <h4>©Copyrights are Reserved by stake.Apetoken.net | 2024</h4>
          </div>
          <div className="copy_link">
            <a href="https://t.me/ApeOnBitrock" target='blank'>@apekingbitrock</a>
            <a href="https://explorer.bit-rock.io/address/0xE8a4F717Ac5b08BcCc7240D649af653b2577b36a" target='blank'>Contract Address</a>
            <a href="https://rock-swap.io/#/swap?outputCurrency=0xe8a4f717ac5b08bccc7240d649af653b2577b36a" target='blank'>Buy $APE</a>
          </div>
 </div>
        </div>
      </div>
    </div>
    
  );
}

export default App;
