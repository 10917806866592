/** @format */


import styled from "styled-components";


const Wrapper = styled.div`
nav{
    padding: 8px 0px;
position: fixed;
width: 100%;
z-index: 99999;
background-color: #000000;
left: 0;
right: 0;
top: 0;
}
.navbarSingleItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 100%;
}
.menu{
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.menu ul{
    display: flex;
    align-items: center;
    column-gap: 30px;
}
.menu ul li{
    list-style: none;
    display: flex;

}
.menu ul li a{
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    color: #CCCCCC;
    font-size: 16px;
    transition: 0.5s ease;
    cursor: pointer;

}
.menu ul li a:hover{
    color: #FED000;
    transform: scale(1.1);
}

.nav_button{
    display: flex;
    column-gap: 20px;
}
.nav_button a{
    text-decoration: none;
    font-family: 'VT323', monospace;
    color: white;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.5s ease;
}
.nav_button a:nth-child(2){
    background-color: #2571E9;
}
.nav_button a:hover{
    transform: scale(1.1);
}
@media (max-width:1192px) {
.nav_button a {
 
    padding: 10px 11px;
 
}
.nav_logo img{
    width: 150px;
}
.menu ul {
   
    column-gap: 12px;
}
    
}
@media (max-width: 992px) {
    nav{
        display: none;
    }
    
}

@media (max-width:992px) {
  display: block;
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: 656599;
  padding: 8px 0px;
  top: 0;
}
.mobile_menu{
    display: none;
}
@media (max-width: 992px) {
    .mobile_menu{
    display: flex;
}
    
}
 .offcanvas-menu {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background-color: #333;
    -webkit-transition: left 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    z-index: 5454545488;
    background: #000000;
}

.offcanvas-menu.open {
  left: auto;
  right: 0;
}

.toggle-button {
  position: fixed;
  top: 18px;
  left: auto;
  right: 20px;
  font-size: 20px;
  background: none;
  color: #fff;
  border: none;
  cursor: pointer;
}

.menu-content {
  padding: 20px;
  color: #fff;
  margin-top: 80px;
  display: flex;
  justify-content: flex-start;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content li {
  padding: 10px 0;
  cursor: pointer;
  text-align: left;
}

.menu-content li:hover {
  /* background-color: #555; */
}
.logo{
    padding-left: 20px;
}
.logo img{
  width: 140px;
}
.icon{
  font-size: 28px;
}
.mobileButton{
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  position: absolute;
  top: auto;
  bottom: 20px;
  width: 85%;
  align-items: center;

}
.mobileButton a{
  text-decoration: none;
  color: white;
  font-family: 'VT323', monospace;
  
  font-size: 18px;
  border-radius: 5px;
}

.mobileButton a:nth-child(2){
  padding: 10px 15px;
    background-color: #2571E9;
}
`;
export default Wrapper;
